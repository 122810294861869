<template>
  <div>
    <form @submit.prevent="submit" key="company">
      <div class="font-bold mb-5 text-lg">Profile Information</div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-10">
        <!-- <div class="col-span-1"> -->
          <!-- <form-group
            class="mb-0"
            v-model="form.data.name.value"
            :form="form"
            name="name"
          >
            First Name *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            v-model="form.data.last_name.value"
            :form="form"
            name="last_name"
          >
            Last Name *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            v-model="form.data.email.value"
            :form="form"
            name="email"
          >
            Email *
          </form-group>
        </div> -->
        <div class="col-span-1">
          <form-group
            v-model="form.data.bvn.value"
            :form="form"
            name="bvn"
            class="mb-0"
            :disabled="bvnAlreadyProvided"
          >
            BVN *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.phone_no.value"
            :form="form"
            name="phone_no"
            class="mb-0"
          >
            Phone Number *
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            v-model="form.data.state.value"
            :form="form"
            :options="states"
            type="select"
            name="state"
            class="mb-0"
          >
            State *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.lga.value"
            :form="form"
            :options="lgas"
            type="select"
            name="lga"
            :disabled="!form.data.state.value"
            class="mb-0"
          >
            LGA *
          </form-group>
        </div>
        <div class="col-span-1 md:col-span-2">
          <form-group
            v-model="form.data.address.value"
            :form="form"
            type="textarea"
            name="address"
            class="mb-0"
          >
            Home Address *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.date_of_birth.value"
            :form="form"
            type="date"
            name="date_of_birth"
            class="mb-0"
          >
            Date Of Birth *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.gender.value"
            :form="form"
            :options="genders"
            type="select"
            name="gender"
            class="mb-0"
          >
            Gender *
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            v-model="form.data.account_no.value"
            :form="form"
            name="account_no"
            class="mb-0"
          >
            Account Number *
          </form-group>
          <div
            v-if="bankAccountNeedsVerification"
            class="flex items-center mb-6 mt-3 text-orange-500 text-sm"
          >
            <span class="alert-icon border border-orange-500 mr-2">
              <ion-icon name="alert-outline"></ion-icon>
            </span>
            <span>"<span class="font-bold">{{ trialBankAccountNumber }}</span>" Unverified</span>
          </div>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.bank_name.value"
            :form="form"
            :options="bankOptions"
            type="select"
            name="bank_name"
            class="mb-0"
          >
            Bank Name *
          </form-group>
          <div
            v-if="bankAccountNeedsVerification"
            class="flex items-center mb-6 mt-3 text-orange-500 text-sm"
          >
            <span class="alert-icon border border-orange-500 mr-2">
              <ion-icon name="alert-outline"></ion-icon>
            </span>
            <span>"<span class="font-bold">{{ trialBank }}</span>" Unverified</span>
          </div>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.id_type.value"
            :form="form"
            :options="idTypes"
            type="select"
            name="id_type"
            class="mb-0"
          >
            ID Type
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.id_number.value"
            :form="form"
            name="id_number"
            class="mb-0"
          >
            ID Number
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.marital_status.value"
            :form="form"
            :options="maritalStatuses"
            type="select"
            name="marital_status"
            class="mb-0"
          >
            Marital Status *
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            v-model="form.data.employment_status.value"
            :form="form"
            :options="employmentStatuses"
            type="select"
            name="employment_status"
            placeholder="Select Employment Status"
          >
            Employment  Status *
          </form-group>
        </div>
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div>
        <button type="submit" class="btn btn-blue" :disabled="form.loading">
          <sm-loader class="sm-loader-white" v-if="form.loading" />
          <span v-else>Submit</span>
        </button>
      </div>
    </form>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        User Profile Updated
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    selectedUser: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      employmentStatuses: [
        { title: 'Employed', value: 'employed' },
        { title: 'Self Employed', value: 'self_employed' },
      ],
      idTypes: [
        { title: 'National ID Card', value: 'national_id_card' },
        { title: 'Driver’s License', value: 'drivers_license' },
        { title: 'Permanent Voter’s Card', value: 'voters_card' },
        { title: 'Nigerian International Passport', value: 'international_passport' },
      ],
      genders: [
        { title: 'Male', value: 'male' },
        { title: 'Female', value: 'female' },
      ],
      maritalStatuses: [
        { title: 'Single', value: 'single' },
        { title: 'Engaged', value: 'engaged' },
        { title: 'Married', value: 'married' },
        { title: 'Divorced', value: 'divorced' },
        { title: 'Widowed', value: 'widowed' },
      ],
      form: this.$options.basicForm([
        // { name: 'name', rules: 'required' },
        // { name: 'last_name', rules: 'required' },
        // { name: 'email', rules: 'required' },
        { name: 'phone_no', rules: 'required|phone' },
        { name: 'bvn', rules: 'required|number|length:11' },
        { name: 'date_of_birth', rules: 'required' },
        { name: 'gender', rules: 'required' },
        { name: 'marital_status', rules: 'required' },
        { name: 'address', rules: 'required' },
        { name: 'account_no', rules: 'required|number|length:10' },
        { name: 'bank_name', rules: 'required' },
        { name: 'id_type', rules: 'nullable' },
        { name: 'id_number', rules: 'nullable' },
        { name: 'state', rules: 'required' },
        { name: 'lga', rules: 'required' },
        { name: 'employment_status', rules: 'required' },
      ]),
    };
  },
  computed: {
    ...mapState(['banks']),

    bankAccountNeedsVerification() {
      return this.trialBankAccountNumber && this.trialBank;
    },
    bankAccountDoesntExist() {
      return this.bankAccountNeedsVerification
        && !this.selectedUser?.profile?.trial_bank_account_name;
    },
    bankOptions() {
      return this.banks.length ? this.banks.map((bank) => ({
        title: bank.name,
        value: bank.code,
      })) : [];
    },
    bvnAlreadyProvided() {
      return !!this.selectedUser.profile?.bvn;
    },
    states() {
      return this.$store.state.states.map((state) => state.name);
    },
    lgas() {
      return this.$store.state.states
        .find((state) => state.name === this.form.data.state.value)
        ?.locals.map((lga) => lga.name) || [];
    },
    trialBankAccountNumber() {
      return this.selectedUser?.profile?.trial_account_no;
    },
    trialBank() {
      return this.$options.filters.bankName(
        this.selectedUser?.profile?.trial_bank_code,
        this.banks,
      );
    },
  },
  mounted() {
    this.mapData();
  },
  watch: {
    lgas(lgas) {
      const selectedLga = this.form.data.lga.value;
      if (lgas.indexOf(selectedLga) < 0) {
        this.form.data.lga.value = '';
      }
    },
  },
  methods: {
    mapData() {
      Object.keys(this.form.data).forEach((key) => {
        this.form.data[key].value = this.selectedUser?.profile?.[key] || this.selectedUser?.[key] || '';
      });
    },
    async submit() {
      if (this.validateForm(this.form)) {
        this.form.error = null;
        this.form.setLoading();
        await this.sendRequest('admin.users.updateProfile', this.selectedUser?.id, {
          data: this.getFormData(),
          success: (response) => {
            this.$emit('updated', response.data.user);
            this.$refs.successModal.open();
          },
          error: (error) => {
            this.form.error = error;
            this.mapFormErrors(this.form, error?.response?.data?.errors);
          },
        });
        this.form.setLoading(false);
      }
    },
  },
};
</script>
